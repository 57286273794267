import moment from 'moment';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  OPPORTUNITY_LOCATION_TYPE,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
import {SEARCH_OPPORTUNITIES_POSSIBLE_SORT, SortDirection} from '@joc/api-gateway/lib/api-client';

export enum ORGANIZATION_TABS {
  ALL = 'ALL',
  UPCOMING = 'UPCOMING',
  PENDING = 'PENDING',
  VACANCIES = 'VACANCIES',
  MANUAL = 'MANUAL',
  PAST = 'PAST',
  REJECTED = 'REJECTED',
}

export const PastOpposTabQueryPreset = {
  status: [OPPORTUNITY_STATUSES.CLOSED],
  opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL],
  opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};
export const ManualOpposTabQueryPreset = {
  status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.PENDING],
  opportunityTypesList: [OPPORTUNITY_TYPES.MANUAL],
  opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};
export const VacanciesOpposTabQueryPreset = {
  status: [OPPORTUNITY_STATUSES.OPEN],
  opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC],
  opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
  showVacancies: true,
};
export const PendingOpposTabQueryPreset = {
  status: [OPPORTUNITY_STATUSES.PENDING, OPPORTUNITY_STATUSES.OPEN],
  opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
  volunteerStatus: [OPPORTUNITY_VOLUNTEER_STATUS.PENDING],
};
export const RejectedOpposTabQueryPreset = {
  status: [OPPORTUNITY_STATUSES.CANCELED],
  opportunityTypesList: [OPPORTUNITY_TYPES.MANUAL],
  opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};
export const AllOpposTabQueryPreset = {
  status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.CLOSED],
  opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL],
  opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};
export const UpcomingOpposTabQueryPreset = {
  status: [OPPORTUNITY_STATUSES.OPEN],
  opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL],
  opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
  startDay: {fromDate: moment().utc(true).toISOString()},
  sort: {sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDayStartTime, sortDir: SortDirection.ASC},
};
